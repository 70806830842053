import React from 'react';

const UxUiOutOfTheBox = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.7408 43.9235H8.66094C8.49622 43.9692 8.32174 43.9692 8.15702 43.9235C5.5281 41.7678 3.42669 39.036 2.014 35.9381C0.601314 32.8403 -0.0845681 29.4586 0.00832061 26.0531C-0.00149937 22.4647 0.800219 18.9212 2.35353 15.689C3.90684 12.4568 6.17077 9.62044 8.97512 7.39382C9.12216 7.24919 9.32 7.16797 9.52589 7.16797C9.73179 7.16797 9.92963 7.24919 10.0767 7.39382C10.1519 7.46469 10.2116 7.55008 10.2526 7.64511C10.2936 7.74014 10.3148 7.84256 10.3148 7.94611C10.3148 8.04967 10.2936 8.15209 10.2526 8.24712C10.2116 8.34215 10.1519 8.42793 10.0767 8.49879C7.45438 10.5703 5.33522 13.2129 3.87988 16.2267C2.42453 19.2404 1.67129 22.5465 1.67601 25.8955C1.56841 29.0228 2.17906 32.133 3.46123 34.9854C4.74339 37.8378 6.66223 40.3559 9.0696 42.3448H38.5833C40.9807 40.193 42.8792 37.5407 44.1456 34.5738C45.412 31.6069 46.0151 28.3976 45.9131 25.1717C45.8112 21.9457 45.0061 18.781 43.5549 15.9006C42.1037 13.0202 40.0417 10.4938 37.5132 8.49879C37.438 8.42793 37.3783 8.34215 37.3373 8.24712C37.2963 8.15209 37.2751 8.04967 37.2751 7.94611C37.2751 7.84256 37.2963 7.74014 37.3373 7.64511C37.3783 7.55008 37.438 7.46469 37.5132 7.39382C37.6603 7.24919 37.8581 7.16797 38.064 7.16797C38.2699 7.16797 38.4678 7.24919 38.6148 7.39382C41.3347 9.55731 43.5477 12.2939 45.0973 15.4104C46.6469 18.5268 47.4953 21.947 47.5824 25.4287C47.6694 28.9105 46.9924 32.3688 45.6005 35.5593C44.2086 38.7498 42.1358 41.5943 39.5274 43.8919C39.4085 43.9604 39.2749 43.9993 39.1379 44.0048C39.0009 44.0103 38.8647 43.9823 38.7408 43.9235Z"
      fill="#475467"
    />
    <path
      d="M37.8586 8.65239H31.9745C31.7658 8.65239 31.5658 8.56917 31.4183 8.42115C31.2708 8.27312 31.1879 8.07241 31.1879 7.86307C31.1879 7.65373 31.2708 7.45264 31.4183 7.30461C31.5658 7.15658 31.7658 7.07375 31.9745 7.07375H37.7641C37.9713 7.00593 38.1634 6.89843 38.3303 6.75771C38.5526 6.50478 38.6759 6.1796 38.6767 5.84236C38.6809 5.66973 38.6505 5.4978 38.5876 5.33709C38.5246 5.17637 38.4297 5.03015 38.3095 4.90658C38.1893 4.78302 38.0464 4.68454 37.8878 4.61753C37.7292 4.55051 37.5583 4.51611 37.3862 4.51616H9.94961C9.59339 4.52292 9.25405 4.67043 9.00554 4.92662C8.76922 5.17319 8.63477 5.50032 8.62837 5.84236C8.63176 6.12245 8.72549 6.39386 8.89569 6.61588C9.06589 6.83791 9.30352 6.99862 9.57244 7.07375H20.4904C20.699 7.07375 20.899 7.15658 21.0465 7.30461C21.1941 7.45264 21.277 7.65373 21.277 7.86307C21.277 8.07241 21.1941 8.27312 21.0465 8.42115C20.899 8.56917 20.699 8.65239 20.4904 8.65239H9.28899C8.6593 8.51437 8.09629 8.16227 7.69505 7.65611C7.29381 7.14994 7.07905 6.52069 7.08666 5.87397C7.07806 5.4943 7.14631 5.11685 7.28715 4.76437C7.428 4.41188 7.63868 4.09134 7.90629 3.82281C8.1739 3.55427 8.49295 3.34324 8.84422 3.20191C9.19549 3.06058 9.57126 2.99209 9.94961 3.00073H37.3862C37.952 2.9988 38.5061 3.16504 38.9778 3.47864C39.4495 3.79223 39.8175 4.23923 40.0364 4.76283C40.2552 5.28642 40.3146 5.86309 40.2069 6.42048C40.0992 6.97787 39.8298 7.49067 39.4318 7.89429C39.0508 8.28972 38.5548 8.55518 38.0153 8.65239H37.8586Z"
      fill="#475467"
    />
    <path
      d="M9.79076 34.92H9.47658C9.32831 34.8508 9.20351 34.7385 9.11938 34.5978C9.03526 34.4571 8.99521 34.2945 9.00416 34.1307L10.0742 19.702C10.0864 19.5416 10.1474 19.3886 10.2486 19.2638C10.3497 19.139 10.4863 19.0483 10.6404 19.0037C10.7944 18.959 10.9578 18.9626 11.1097 19.0141C11.2616 19.0655 11.3944 19.1624 11.4899 19.2916L14.8883 24.0591C17.1537 22.7015 24.9564 18.4391 30.966 19.6388C32.9772 20.0122 34.8008 21.0641 36.1342 22.6204C37.4677 24.1766 38.2311 26.1436 38.2974 28.195C38.2974 28.4475 37.4795 34.0043 27.4739 34.0043C23.0694 33.8178 18.788 32.4909 15.0458 30.1525L10.3262 34.6676C10.2627 34.7484 10.1809 34.8133 10.088 34.8572C9.9952 34.901 9.89334 34.9225 9.79076 34.92ZM14.9198 28.4158C15.0631 28.3699 15.2167 28.3699 15.36 28.4158C18.997 30.7405 23.1685 32.0889 27.4739 32.3312C35.6231 32.3312 36.6613 28.4161 36.7242 27.9425C36.6789 26.2556 36.0473 24.6374 34.9382 23.3688C33.8291 22.1003 32.3128 21.2609 30.6518 20.9963C24.359 19.7333 15.2032 25.5114 15.1088 25.5745C14.9382 25.6798 14.7352 25.7185 14.538 25.6836C14.3409 25.6487 14.163 25.5424 14.0387 25.3849L11.4899 21.7856L10.7348 32.0152L14.3844 28.5106C14.4562 28.4445 14.5414 28.3936 14.6333 28.3611C14.7252 28.3285 14.8225 28.3152 14.9198 28.3214V28.4158Z"
      fill="#475467"
    />
    <path
      d="M31.3741 26.3371C31.1003 26.3371 30.8329 26.2555 30.6052 26.1028C30.3775 25.9502 30.1999 25.7334 30.0951 25.4796C29.9904 25.2258 29.9626 24.9463 30.016 24.6768C30.0694 24.4073 30.2019 24.16 30.3955 23.9657C30.5891 23.7714 30.8352 23.6389 31.1037 23.5853C31.3723 23.5317 31.6512 23.5592 31.9042 23.6643C32.1571 23.7695 32.3727 23.9477 32.5248 24.1761C32.677 24.4046 32.7584 24.673 32.7584 24.9477C32.7584 25.3162 32.6124 25.6696 32.3528 25.9301C32.0931 26.1907 31.7413 26.3371 31.3741 26.3371ZM31.3741 24.7585C31.3241 24.7585 31.2759 24.7785 31.2405 24.814C31.2051 24.8495 31.1852 24.8975 31.1852 24.9477C31.1852 25.1687 31.5631 25.1687 31.5631 24.9477C31.5631 24.8975 31.5432 24.8495 31.5078 24.814C31.4724 24.7785 31.4242 24.7585 31.3741 24.7585Z"
      fill="#475467"
    />
    <path
      d="M17.218 24.3142C17.0199 24.3149 16.8284 24.2404 16.6826 24.1057C16.5368 23.9711 16.4473 23.7863 16.4314 23.5881L15.9283 18.0945C15.906 17.9162 15.9471 17.7357 16.045 17.5853C16.143 17.435 16.2913 17.3245 16.4629 17.2735C19.9893 15.9309 23.7072 15.1631 27.4753 15C30.6217 15.5367 31.5344 19.9257 31.5658 20.1152C31.6026 20.3202 31.557 20.5314 31.4391 20.7029C31.3212 20.8745 31.14 20.9922 30.9359 21.0305C30.7326 21.0594 30.5265 21.0107 30.3575 20.8937C30.1885 20.7767 30.0695 20.6002 30.0241 20.3992C30.0241 20.3992 29.3629 16.9263 27.1919 16.5474C23.8968 16.7103 20.6476 17.3919 17.5637 18.5678L18.0046 23.4301C18.0132 23.5338 18.0011 23.6382 17.9693 23.7373C17.9375 23.8363 17.8868 23.9282 17.8195 24.0074C17.7522 24.0867 17.67 24.1518 17.5775 24.199C17.4851 24.2462 17.3844 24.2747 17.281 24.2826L17.218 24.3142Z"
      fill="#475467"
    />
    <path
      d="M18.726 36.0906C18.2442 36.1262 17.7606 36.1262 17.2788 36.0906C16.209 35.9012 15.6109 35.554 15.4221 35.0172C15.3571 34.8278 15.3461 34.624 15.3906 34.4287C15.4351 34.2334 15.5335 34.0545 15.6741 33.9123C16.7124 32.7756 18.6002 30.9444 18.8205 30.755C18.8911 30.6795 18.9762 30.6192 19.0709 30.5781C19.1656 30.5369 19.2681 30.5156 19.3712 30.5156C19.4744 30.5156 19.5761 30.5369 19.6708 30.5781C19.7655 30.6192 19.8506 30.6795 19.9213 30.755C19.9974 30.8286 20.0581 30.9167 20.0995 31.0143C20.1408 31.112 20.1625 31.217 20.1625 31.3231C20.1625 31.4292 20.1408 31.5341 20.0995 31.6318C20.0581 31.7294 19.9974 31.8179 19.9213 31.8915C19.9213 31.8915 18.4746 33.2805 17.4362 34.3855C18.6551 34.6115 19.9094 34.5555 21.1035 34.2217C22.2975 33.888 23.3997 33.2854 24.3267 32.4596C24.4737 32.315 24.6716 32.2342 24.8775 32.2342C25.0834 32.2342 25.2804 32.315 25.4275 32.4596C25.5716 32.6072 25.6525 32.8057 25.6525 33.0123C25.6525 33.2189 25.5716 33.4171 25.4275 33.5646C24.5609 34.4497 23.5124 35.1344 22.3548 35.5707C21.1973 36.0069 19.959 36.1844 18.726 36.0906Z"
      fill="#475467"
    />
  </svg>
);

export default UxUiOutOfTheBox;
